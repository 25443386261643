:root {
  --text-color: #007bff;
  --link-color: #60a5fa;
  --secondary-color: #6c757d;
  --content-font-size: 12px;
  --font-family: "sans-serif";
  --background-color: #0f0f0f;
  --title-font-size: 20px;
  --title-bold-weight: 700;
  --heading-font-size: 20px;
  --subheading-font-size: 16px;
}

.App {
  text-align: center;
  padding: clamp(
    4rem,
    8vw,
    16rem
  ); /* Minimum 1rem, preferred 2vw, maximum 4rem */
  background-color: var(--background-color);
  height: 100%;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
p,
li,
a {
  color: var(--text-color);
  font-family: var(--font-family);
  text-align: left;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

p {
  font-size: var(--p-font-size);
}

h1 {
  font-size: var(--title-font-size);
  font-weight: var(--title-bold-weight);
}

h2 {
  font-size: var(--heading-font-size);
}

h3 {
  font-size: var(--subheading-font-size);
}
