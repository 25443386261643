:root {
  --primary-color: #007bff;
  --heading-font-size: 16px;
  --subheading-font-size: 12px;
  --font-family: "sans-serif";
}

.heading,
.subheading {
  text-align: left;
  font-family: var(--font-family);
}

.heading {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
}

.subheading {
  color: var(--primary-color);
  font-size: var(--subheading-font-size);
}

.container {
  display: flex;
  flex-direction: row; /* Align items side by side by default */
  align-items: center; /* Center items vertically */
  gap: 50px; /* Add some space between the items */
  align-items: flex-start;
  width: 100%;
  padding-bottom: 40px;
}

.item {
  flex: 1;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack items on top of each other */
    align-items: flex-start; /* Align items to the start of the container */
  }
}
