:root {
  --primary-color: #007bff;
  --hover-color: #649dd9;
  --hover-weight: 700;
  --font-size: 20px;
  --font-family: "sans serif";
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.navItem {
  text-align: left;
  font-family: var(--font-family);
}

.navLink {
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--font-size);
}

.navLink:hover {
  color: var(--hover-color);
  font-weight: var(--hover-weight);
}
