:root {
  --primary-color: #007bff;
  --heading-font-size: 16px;
  --subheading-font-size: 12px;
  --font-family: "sans-serif";
  --heading-bold-size: 700;
}

.heading,
.subheading {
  text-align: left;
  font-family: var(--font-family);
}

.heading {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: var(--heading-bold-size);
}

.subheading {
  color: var(--primary-color);
  font-size: var(--subheading-font-size);
}
