:root {
  --primary-color: #007bff;
  --font-family: "sans serif";
  --background-color: #ffffff;
  --heading-font-size: 20px;
  --heading-bold-weight: 700;
  --subheading-font-size: 16px;
  --content-font-size: 16px;
  --small-font-size: 12px;
}

.pillContainer {
  display: flex;
  gap: 4px; /* Adds space between the pills */
  flex-wrap: wrap; /* Allows pills to wrap to the next line if needed */
  padding-top: 20px;
}

.pillContainer span {
  font-size: var(--small-font-size);
  color: var(--primary-color);
  font-family: var(--font-family);
  text-align: center;
  background-color: var(
    --background-color
  ); /* Optional: Change to a background color that contrasts with the text */
  border-radius: 20px; /* Rounds the corners to create the pill shape */
  padding: 6px 12px; /* Adds padding inside the pills */
  white-space: nowrap; /* Prevents text from wrapping inside the pill */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.pillContainer span:hover {
  background-color: var(--primary-color); /* Change background on hover */
  color: var(--background-color); /* Change text color on hover */
}

.projectSection {
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  padding-bottom: 150px;
}

.projectSection img {
  width: 300px; /* Adjust this value based on the desired preview size */
  height: 200px; /* Maintain aspect ratio */
  object-fit: cover; /* Crop the image if necessary to fit the dimensions */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.projectSection a {
  display: block;
  text-align: left;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .projectSection {
    flex-wrap: wrap; /* Enable wrapping on smaller screens */
  }

  .projectSection img {
    width: 100%; /* Make the image take up the full width on smaller screens */
    height: auto; /* Adjust height automatically */
  }
}
